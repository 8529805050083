export function getBearerTokenOpts(auth0Token: string) {
  return {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };
}

export async function fetchWithAuth0Token(
  url: string,
  auth0Token: string,
  opts: RequestInit = {},
) {
  return await fetch(
    url,
    Object.assign({}, opts, getBearerTokenOpts(auth0Token)),
  );
}

export function checkGWAuth0UserRole(user: object | undefined) {
  if (user) {
    const roles = user['https://safeguardglobal.com/roles'];
    console.log(roles);
    if (
      roles.includes('workforce_analytics__basic_user') ||
      roles.includes('workforce_analytics__pro_user')
    ) {
      console.log('VALID ROLE');
    } else {
      window.location.replace(
        'https://www.app.safeguardglobal.com/?error=No%20Access%20to%20Workforce%20Analytics',
      );
    }
  } else {
    window.location.replace(
      'https://www.app.safeguardglobal.com/?error=No%20Access%20to%20Workforce%20Analytics',
    );
  }
  return true;
}
