import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export default function SignOut() {
  const { isAuthenticated, logout } = useAuth0();

  if (!isAuthenticated) {
    return null;
  }
  const subpage = window.location.pathname.substring(1); // Get the current subpage
  const logoutUrl = window.location.origin + '/' + subpage;
  return (
    <button
      className=" hover:shadow px-2 border border-gray-300 rounded"
      onClick={() => logout({ logoutParams: { returnTo: logoutUrl } })}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-8"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
        />
      </svg>
    </button>
  );
}
