import {
  QuestionMarkCircleIcon,
  ChartBarSquareIcon,
} from '@heroicons/react/24/outline';

interface GWSidebarProps {
  activeSubpage: string;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function GWSidebar(props: GWSidebarProps) {
  const { activeSubpage } = props;
  const navigation = [
    {
      name: 'Global Workforce Questions',
      href: '/global-workforce-analytics-questions-preview',
      current: activeSubpage === 'Global Workforce Questions' ? true : false,
      icon: QuestionMarkCircleIcon,
    },
    // {
    //   name: 'Global Workforce Dashboard',
    //   href: '/global-workforce-analytics-dashboard-preview',
    //   current: activeSubpage === 'Global Workforce Dashboard' ? true : false,
    //   icon: ChartBarSquareIcon,
    // },
  ];
  return (
    <div>
      <ul role="list" className="-mx-2 space-y-1">
        {navigation.map(item => (
          <li key={item.name}>
            <a
              href={item.href}
              className={classNames(
                item.current
                  ? `font-semibold flex w-full cursor-pointer items-center gap-3 rounded-lg p-3 text-sm text-supporting-cyan
                  duration-200 hover:bg-white/10  bg-white/10 mt-2 active`
                  : `font-semibold flex w-full cursor-pointer items-center gap-3 rounded-lg p-3 text-sm
                  text-white hover:text-supporting-cyan duration-200 hover:bg-white/10 mt-2`,
              )}
            >
              <item.icon
                className={classNames(
                  item.current
                    ? 'hover:bg-white/10'
                    : 'hover:text-supporting-cyan',
                  'h-6 w-6 shrink-0',
                )}
                aria-hidden="true"
              />
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
