import React, { useState, useRef } from 'react';

interface JobSearchProps {
  setSelectedJobTitle: React.Dispatch<React.SetStateAction<string>>;
  selectedJobTitle: string;
}

const JobSearch: React.FC<JobSearchProps> = ({
  setSelectedJobTitle,
  selectedJobTitle,
}) => {
  const jobArray = [
    'all job titles',
    //Chief Executives
    'Chief Executive',
    'CEO',
    'CTO',
    'CPO',
    'CFO',
    // Database and Network Administrators and Architects
    'Network Engineer',
    'Database Programmer',
    'Database Security Administrator',
    'Data Architect',
    'Data Integration',
    'Data Warehousing',
    'Network Analyst',
    'Network Admin',
    'Database Developer',
    'Database Security',
    // Secretaries and Administrative Assistants
    'Executive Assistant',
    'Secretary',
    'Administrative Assistant',
    // Sales Representatives, Wholesale and Manufacturing
    'Sales Representative',
    'Wholesale Salesperson',
    //Management Analysts
    'Business Analyst',
    'Business Consultant',
    'Industrial Analyst',
    // Human Resources Workers
    'Recruiter',
    'Staffing Coordinator',
    'Labor Relations',
    'HR',
    // General and Operations Managers
    'Operational Risk Manager',
    'Operations Manager',
    'General Manager',
    // Financial Examiners
    'Payroll Expert',
    // Financial Managers
    'Financial Manager',
    'Bank Manager',
    // Customer Service Representatives
    'Customer Service',
    'Customer Complaint Clerk',
    // // Computer and Information Systems Managers
    // 'Information Systems Manager',
    // 'VP of Software Engineering',
    // 'Director of Software Engineering',
    // Computer and Information Analysts
    'Applications Analyst',
    'Systems Analyst',
    'Systems Architect',
    'Information Analyst',
    'Security Analyst',
    // Computer Support Specialists
    'Network Support Specialist',
    'Network Technician',
    'Help Desk Technician',
    'IT Support',
    // Advertising and Promotions Managers
    'Advertising Manager',
    'Promotions Manager',
    // Administrative Services and Facilities Managers
    'Administrative Services Manager',
    'Facilities Manager',
    // Accountants and Auditors
    'Accountant',
    'Auditor',
    // Marketing and Sales Managers
    'Marketing Manager',
    'Sales Manager',
    // Software and Web Developers, Programmers, and Testers
    'Software Developer',
    'Software Engineer',
    'Software Programmer',
    'Software Tester',
    'Software Quality Engineer',
    'Web Developer',
    // Data Scientists
    'Business Intelligence Analyst',
    'BI',
    'Data Analyst',
    'Data Scientist',
    // Logisticians and Project Management Specialists
    'Project Management Specialist',
    'Project Manager',
    'Logistics Specialist',
    'Product Manager',
    // Civil Engineers
    'Civil Engineer',
    'Construction Engineer',
    // Designers
    'Commercial and Industrial Designer',
    'Fashion Designer',
    'Graphic Designer',
    'Interior Designer',
    'Set and Exhibit Designer',
  ];
  const [query, setQuery] = useState('');
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputQuery = event.target.value;
    if (inputQuery === '') {
      setSelectedJobTitle('all job titles');
    } else {
      setSelectedJobTitle(inputQuery);
    }

    setQuery(inputQuery);
    setDropdownOpen(inputQuery !== '' && filteredJobs.length > 0);
  };

  const handleJobSelect = (job: string) => {
    setQuery(job);
    setDropdownOpen(false);
    setSelectedJobTitle(job);
  };

  const handleInputClick = () => {
    if (query !== '' && filteredJobs.length > 0) {
      setDropdownOpen(true);
    }
  };

  const filteredJobs = jobArray.filter(job =>
    job.toLowerCase().includes(query.toLowerCase()),
  );

  return (
    <div className={`relative`}>
      <input
        type="text"
        value={query}
        placeholder="all job titles"
        onChange={handleInputChange}
        onClick={handleInputClick}
        className="mt-2 text-sm border border-gray-300 rounded p-2 w-64"
        ref={inputRef}
      />
      {isDropdownOpen && filteredJobs.length > 0 && (
        <div
          className="text-sm absolute top-10 left-0 z-10 bg-white border border-gray-300 rounded mt-1 w-64"
          ref={dropdownRef}
        >
          {filteredJobs.map(job => (
            <div
              key={job}
              onClick={() => handleJobSelect(job)}
              className="text-sm cursor-pointer p-2 hover:bg-gray-100"
            >
              {job}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default JobSearch;
