import React, { useState } from 'react';
import JobSearch from './JobTitleSearch';

interface SentenceGeneratorProps {
  submitQQuestion: (text: string) => void;
}

const SentenceGenerator: React.FC<SentenceGeneratorProps> = ({
  submitQQuestion,
}) => {
  const jobLevels = [
    { value: '', label: 'all levels' },
    { value: 'junior', label: 'junior' },
    { value: 'IC/mid-level', label: 'IC/mid-level' },
    { value: 'senior', label: 'senior' },
    { value: 'manager', label: 'manager' },
    { value: 'leadership', label: 'leadership' },
  ];

  const dimensions = [
    { value: 'country', label: 'country' },
    { value: 'region', label: 'region' },
    { value: 'job family', label: 'job family' },
    { value: 'job level', label: 'job level' },
    { value: 'age group', label: 'age group' },
    { value: 'report date', label: 'report date' },
    { value: 'year', label: 'year' },
  ];

  const years = [
    { value: '2015 to 2024', label: '2015 to 2024' },
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
    { value: '2019', label: '2019' },
    { value: '2018', label: '2018' },
    { value: '2017', label: '2017' },
    { value: '2016', label: '2016' },
    { value: '2015', label: '2015' },
  ];

  const salaryStr = 'Median Salary of';
  const [jobLevel, setJobLevel] = useState('');
  const [selectedJobTitle, setSelectedJobTitle] = useState('all job titles');
  const [dimension, setDimension] = useState('country');
  const [year, setYear] = useState('2015 to 2024');

  const handleRunButtonClick = () => {
    const generatedSentence = `${salaryStr} ${jobLevel} ${selectedJobTitle} by ${dimension} for ${year}`;
    submitQQuestion(generatedSentence);
  };

  return (
    <div className="relative z-30 mx-5 p-4 bg-white shadow-md rounded-md space-y-4">
      <h3 className="text-lg font-semibold leading-6 text-gray-900">
        Question Creator
      </h3>
      <p className="text-sm text-gray-600">
        Create a question to suit your preferences, then click on  &rsquo;Ask
        Question&rsquo; to send it to the Question Bar.
      </p>
      <div
        className="bg-gray-100 px-2 py-4 ring-1 ring-indigo-800 rounded-lg
        flex justify-center items-center flex-wrap space-x-2"
      >
        <div className="mt-2 text-gray-700 text-sm">{salaryStr}</div>

        {/* Job Level */}
        <select
          className="mt-2 block w-32 rounded-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset
          ring-gray-300 focus:ring-2 focus:ring-indigo-600 text-sm"
          value={jobLevel}
          onChange={e => setJobLevel(e.target.value)}
        >
          {jobLevels.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        {/* Job Search (Job Title) */}
        <JobSearch
          setSelectedJobTitle={setSelectedJobTitle}
          selectedJobTitle={selectedJobTitle}
        />

        <div className="mt-2 text-gray-700 text-sm">By</div>
        {/* By Select */}
        <select
          className="mt-2 block w-32 rounded-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset
          ring-gray-300 focus:ring-2 focus:ring-indigo-600 text-sm"
          value={dimension}
          onChange={e => setDimension(e.target.value)}
        >
          {dimensions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <div className="mt-2 text-gray-700 text-sm">For</div>
        {/* For Select */}
        <select
          className="mt-2 block w-32 rounded-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset
          ring-gray-300 focus:ring-2 focus:ring-indigo-600 text-sm"
          value={year}
          onChange={e => setYear(e.target.value)}
        >
          {years.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        {/* Run Button */}
        <button
          className="mt-2 rounded bg-amethyst px-2 py-1 text-sm font-semibold text-white shadow-sm
          hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
          focus-visible:outline-offset-2 focus-visible:outline-indigo-800"
          onClick={handleRunButtonClick}
        >
          Ask Question
        </button>
      </div>
    </div>
  );
};

export default SentenceGenerator;
