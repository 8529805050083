import { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

export default function GWLegalNotice() {
  const [showWarning, setShowWarning] = useState(true);
  return (
    <>
      <div className="flex-1">
        {showWarning && (
          <div className="flex items-center bg-slate-200 mx-12 mt-5 px-6 py-2.5 sm:before:flex-1">
            <p className="text-sm text-indigo-950 leading-6">
              <strong className="font-semibold">Terms of Use: </strong>
              By using this service, you confirm your agreement to the terms &
              conditions governing data access and usage.
              <br />
              Please review the{' '}
              <a
                className="underline"
                href="/Global_Workforce_EULA.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                End-User License Agreement (EULA)
              </a>
            </p>
            <div className="flex flex-1 justify-end">
              <button
                type="button"
                className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
