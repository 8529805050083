import GWSidebar from './GWSidebar';
import { HomeIcon, ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';

interface SidebarProps {
  activeSubpage: string;
}

const tools = [
  {
    name: 'The Global Unity Homepage',
    href: 'https://app.safeguardglobal.com/',
    icon: HomeIcon,
    current: false,
  },
  {
    name: 'ChatSG',
    href: 'https://chat.safeguardglobal.com/',
    icon: ChatBubbleOvalLeftEllipsisIcon,
    current: false,
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Sidebar(props: SidebarProps) {
  return (
    <div>
      {(props.activeSubpage === 'Global Workforce Questions' ||
        props.activeSubpage === 'Global Workforce Dashboard') && (
        <GWSidebar activeSubpage={props.activeSubpage} />
      )}
      <ul>
        <li>
          <div className="mt-28 text-sm font-semibold leading-6 text-gray-400">
            Other Apps and Tools
          </div>
          <ul role="list" className="-mx-2 mt-2 space-y-1">
            {tools.map(item => (
              <li key={item.name}>
                <a
                  href={item.href}
                  target={item.name === 'The Global Unity Homepage' ? "_self" : "_blank"}
                  rel="noreferrer"
                  className={classNames(
                    `font-semibold flex w-full cursor-pointer items-center gap-3 rounded-lg p-3 text-sm
                    text-white hover:text-supporting-cyan duration-200 hover:bg-white/10 mt-1`,
                  )}
                >
                  <item.icon
                    className={classNames(
                      'hover:text-supporting-cyan',
                      'h-6 w-6 shrink-0',
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
}
