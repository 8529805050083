import { fetchWithAuth0Token } from './auth0';

export async function requestEmbed(
  service: string,
  resource: string,
  auth0Token: string,
  opts?: any,
): Promise<any> {
  try {
    const env = `${process.env.REACT_APP_ENVIRONMENT_NAME}`;
    let qsApiUrl;
    if (env != 'prd') {
      qsApiUrl = `https://api.qs.data-${env}.safeguardglobal.com/qs_embed/${service}?res=${resource}`;
    } else {
      qsApiUrl = `https://api.qs.data.safeguardglobal.com/qs_embed/${service}?res=${resource}`;
    }
    const result = await fetchWithAuth0Token(qsApiUrl, auth0Token, opts);
    const data = await result.json();
    return data;
  } catch (er: any) {
    console.log(er);
    throw er;
  }
}
