import { useEffect, useState, Fragment } from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import { requestEmbed } from './utils/api';
import { embedQ } from './utils/quicksight';
import SessionTimeoutDiv from './components/SessionTimeout';
import { checkGWAuth0UserRole } from './utils/auth0';
import SentenceGenerator from './components/SentenceCreator';
import GWLegalNotice from './components/GWLegalNotice';
import Demo from './components/Demo';

function GWQuestions() {
  const [auth0Token, setAuth0Token] = useState('');
  const { getAccessTokenSilently, user } = useAuth0();

  const [qBar, setQBar] = useState<any>();

  checkGWAuth0UserRole(user);

  async function embedQDashboardWithToken(auth0Token: string) {
    // console.log(auth0Token);
    const qcResponse = await requestEmbed('wfa', 'q_topic', auth0Token);
    const qUrl = qcResponse.q_embed_url;
    const qBar = embedQ('global-workforce-questions-preview', qUrl);
    setQBar(qBar);
  }

  function submitQQuestion(text: string) {
    qBar.setQBarQuestion(text);
  }

  // run only on once
  useEffect(() => {
    document.title = 'Global Workforce Analytics - Questions';
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAuth0Token(token);
      embedQDashboardWithToken(token);
    };
    fetchAccessToken().catch(console.error);
  }, [getAccessTokenSilently]);

  const stats = [
    {
      id: 1,
      name: `Click on the questions below to interact with the dataset.
      Ask a similar or modified question to get the answer you need.`,
      value: 'Sample Questions',
    },
    {
      id: 2,
      name: `The Preview dataset has a limited number of records from 2015 until now,
      with several countries and job titles removed.`,
      value: 'Preview Version Limitations',
    },
    // {
    //   id: 3,
    //   name: `Explore available data fields by clicking on the 3-dot menu.
    //   Change the visual type from the right-side menu after asking your question`,
    //   value: 'Visual Types',
    // },
  ];

  const items1 = [
    'What is the Median Salary for a Software Engineer by Job Level?',
    'What is the Median Salary for a Software Developer by Country?',
    'Give me YoY % in Salary for 2020 vs 2019',
    'What is the Median Salary of a Sales Manager in the UK?',
    'Forecast the Median Salary by Quarter',
  ];
  const items2 = [
    'What is the Median Salary of a Sales Manager for the UK?',
    'Forecast the Median Salary of a Software Engineer by Quarter',
    'Yearly Median Wage Trend per the Tech, Finance and Sales Job Family',
    'What is the Median Salary for each Job Family?',
    'Give me the Bottom 10 Countries by Salary in the Business Job Family',
    'Which Countries in EMEA have had the Lowest Wage since 2019?',
    'Yearly Trend of Salary for Senior Software Engineers in the UK vs Canada vs India',
  ];

  return (
    <>
      <SessionTimeoutDiv />

      <div
        className="mx-auto px-4 max-w-6xl text-center"
        style={{ minWidth: '700px' }}
      >
        <h1 className="text-2xl font-semibold tracking-tight text-gray-900">
          Global Workforce Questions - Preview
        </h1>
        <p className="m-2 text-base leading-6 text-gray-600">
          Get insights into compensation trends around the world. Ask questions
          about the global workforce in plain English and receive visualized
          answers
        </p>
        <div className="m-4 flex items-center justify-center gap-x-6">
          <Demo />
        </div>
      </div>
      <SentenceGenerator submitQQuestion={submitQQuestion} />
      <div
        className="flex min-h-screen h-full flex-col text-sm"
        style={{ minWidth: '700px' }}
      >
        <div className="flex w-full pt-[48px] sm:pt-0">
          <div className="w-full">
            <div className="m-5">
              <div className="card relative fixed-height overflow-hidden">
                <div
                  className="relative z-20 h-full w-full border"
                  id="global-workforce-questions-preview"
                ></div>
                <div className="absolute z-10 bottom-10 left-0 w-full bg-white">
                  <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:max-w-none">
                      <dl className="my-2 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center md:grid-cols-2">
                        {stats.map(stat => (
                          <div
                            key={stat.id}
                            className="flex flex-col bg-gray-100 p-8"
                          >
                            <dt className="text-sm font-normal leading-6 text-gray-600">
                              {stat.name}
                            </dt>
                            <dd
                              className={
                                stat.id === 2
                                  ? 'order-first text-lg font-semibold tracking-tight text-gray-600'
                                  : 'order-first text-lg font-semibold tracking-tight text-gray-600'
                              }
                            >
                              {stat.value}
                            </dd>
                          </div>
                        ))}
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {qBar && (
              <div className="mx-4">
                <div className="grid grid-cols-1">
                  {/* <div className="card m-4 mt-0 border">
                    <div className="card-header bg-success text-neutral-50 font-bold ">
                      HR & Finance Questions
                    </div>
                    <ul className="list-group list-group-flush">
                      {items1.map((item, index) => (
                        <li
                          key={index}
                          className="list-group-item hover:text-[#07323b] hover:bg-slate-100"
                          role="button"
                          onClick={() => submitQQuestion(item)}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div> */}
                  <div className="rounded-t border-gray-200 bg-supporting-teal mx-4 px-4 py-2 sm:px-6">
                    <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div className="ml-4 mt-4">
                        <h3 className="text-base font-semibold leading-6 text-neutral-50">
                          Sample Questions
                        </h3>
                        <p className="mt-1 text-sm text-neutral-50">
                          Click on the sample questions below to send the
                          question to the Question Bar.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="overflow-hidden rounded-md bg-white shadow mx-4">
                    <ul role="list" className="divide-y divide-gray-200">
                      {items2.map((item, index) => (
                        <li
                          key={index}
                          className="px-4 py-2 hover:text-[#07323b] hover:bg-slate-100"
                          role="button"
                          onClick={() => submitQQuestion(item)}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <div />
          </div>
        </div>
      </div>
      <div className="text-center">
        <GWLegalNotice />
      </div>
    </>
  );
}

export default GWQuestions;
