import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import '../App.css';

function embedDashboard(containerId, url) {
  const container = document.getElementById(containerId);

  const options = {
    url,
    container,
    scrolling: 'no',
    iframeResizeOnSheetChange: true, // use this option in combination with height: AutoFit,
    // to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
    width: '100%',
    height: 'AutoFit',
    loadingHeight: '800px',
    footerPaddingEnabled: true,
    sheetTabsDisabled: false, // use this option to enable or disable sheet tab controls
    undoRedoDisabled: true, // use this option to disable undo and redo buttons
    resetDisabled: false, // use this option to disable reset button
  };

  const dashboard = QuickSightEmbedding.embedDashboard(options);
  dashboard.on('load', console.log('Loading dashboard...'));
  dashboard.on('error', console.log('Error loading QS dashboard'));
}

function onExpand() {
  // console.log('Q search bar opens');
}
function onCollapse() {
  // console.log('Q search bar closes');
}
function embedQ(containerId, url) {
  const container = document.getElementById(containerId);

  const options = {
    url,
    container,
    width: '100%',
    locale: 'en-US',
    className: 'fixed-height',
    qSearchBarOptions: {
      expandCallback: onExpand,
      collapseCallback: onCollapse,
      iconDisabled: true,
      topicNameDisabled: false,
      allowTopicSelection: false,
    },
  };

  const qBar = QuickSightEmbedding.embedQSearchBar(options);
  qBar.on('load', console.log('Loading Q bar...'));
  qBar.on('error', console.log('Error loading Q bar'));
  return qBar;
}

export { embedDashboard, embedQ };
