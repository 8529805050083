import { useEffect, useState } from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import { requestEmbed } from './utils/api';
import { embedDashboard } from './utils/quicksight';
import SessionTimeoutDiv from './components/SessionTimeout';
import { checkGWAuth0UserRole } from './utils/auth0';
import GWLegalNotice from './components/GWLegalNotice';

function GWDashboard() {
  document.title = 'Global Workforce Analytics - Dashboard';
  const [auth0Token, setAuth0Token] = useState('');
  const { getAccessTokenSilently, user } = useAuth0();

  checkGWAuth0UserRole(user);

  async function embedDashboardWithToken(auth0Token: string) {
    // console.log(auth0Token);
    const qcResponse = await requestEmbed('wfa', 'dashboard', auth0Token);
    console.log(qcResponse);

    const dashboardUrl = qcResponse.dashboard_embed_url;

    embedDashboard(
      'global-workforce-analytics-dashboard-preview',
      dashboardUrl,
    );
  }

  // run only on once
  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAuth0Token(token);
      embedDashboardWithToken(token);
    };
    fetchAccessToken().catch(console.error);
  }, [getAccessTokenSilently]);

  return (
    <>
      <SessionTimeoutDiv />
      <div className="flex min-h-screen h-full flex-col text-sm">
        <div className="flex w-full pt-[48px] sm:pt-0">
          <div className="w-full">
            <div className="mx-auto px-4 max-w-6xl text-center">
              <h1 className="text-2xl font-semibold tracking-tight text-gray-900">
                Global Workforce Dashboard - Preview
              </h1>
              <GWLegalNotice />
              <p className="mt-4 text-base leading-6 text-gray-600">
                Global Workforce Analytics enables companies to gain a better
                understanding of market compensation trends in different job
                groups and countries
              </p>
            </div>

            <div className="flex-1">
              <div
                className="flex flex-col items-center justify-center"
                style={{ minWidth: '360px' }}
              >
                <div
                  className="min-w-full p-4"
                  id="global-workforce-analytics-dashboard-preview"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GWDashboard;
