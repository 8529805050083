import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { XMarkIcon, PlayIcon } from '@heroicons/react/24/outline';

export default function Demo() {
  const [demo, setDemo] = useState(false);
  return (
    <>
      <Link to="#">
        <button
          className="inline-flex items-center gap-x-1.5 rounded-md bg-supporting-ash px-3 py-1.5 text-sm font-semibold text-white
          shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
          focus-visible:outline-gray-600"
          role="alert"
          onClick={() => setDemo(true)}
        >
          <PlayIcon className="-ml-0.5 h-6 w-6" aria-hidden="true" />
          Watch Demo
        </button>
      </Link>
      <Transition.Root show={demo} as={Fragment}>
        <Dialog
          as="div"
          className="w-full h-full relative z-50"
          onClose={setDemo}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg bg-white p-3 text-left shadow-xl
                transition-all"
                >
                  <div>
                    <div className="m-2 text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-7 text-amethyst"
                      >
                        Walkthrough Demo
                      </Dialog.Title>
                      <div
                        aria-hidden="true"
                        className="aspect-[9/10] md:aspect-[9/7] lg:aspect-[9/5] xl:aspect-[5/4] max-h-screen group-hover:opacity-75 mt-2 overflow-hidden group-hover:opacity-75 mt-2"
                      >
                        <button
                          type="button"
                          className="absolute right-4 top-4 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8
                          rounded-full bg-amethyst p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                          focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => setDemo(false)}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                        <svg
                          className="animate-spin h-5 w-5 mr-3 text-black"
                          viewBox="0 0 24 24"
                        ></svg>
                        {/* <img
                          src="https://drive.google.com/uc?id=17j0LgdTLHPkA3knSAD1hzkBv11vxg_ky"
                          alt="..."
                          className="h-full w-full object-cover object-center"
                        /> */}
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/3aW0Kcafjjc?si=Im5VKUEfPNk3QCyx"
                          title="Safeguard Global - Workforce Analytics Demo"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex m-2 mt-4 justify-center">
                    <button
                      type="button"
                      className="inline-flex w-2/4 items-center justify-center rounded-full outline-0 border-1 border-indigo-800
                      px-3 py-2 font-semibold focus:outline-none text-sm"
                      onClick={() => setDemo(false)}
                    >
                      Go back
                    </button>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
